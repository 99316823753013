import { useCallback, useEffect, useState } from 'react';
import {
  Article,
  PaginatedResponse,
  Tag,
  UserComment,
  UserVote,
} from '~/types';

import {
  useFetcher,
  useLoaderData,
  useLocation,
  useSearchParams,
} from '@remix-run/react';

interface PaginatedData {
  paginatedData: PaginatedResponse<Article | Tag | UserVote | UserComment>;
}

const useInfiniteFetcher = <LoaderT extends PaginatedData>() => {
  const { paginatedData } = useLoaderData<LoaderT>();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [data, setData] = useState(
    paginatedData.data as LoaderT['paginatedData']['data'],
  );
  const [nextPage, setNextPage] = useState(
    paginatedData.links.next ? paginatedData.meta.current_page + 1 : null,
  );

  const fetcher = useFetcher<LoaderT>();

  const loadMore = useCallback(() => {
    if (!nextPage) {
      return;
    }

    const currentSort = searchParams.get('sort');
    const sortQuery = currentSort ? `&sort=${currentSort}` : '';

    fetcher.load(`${location.pathname}?index&page=${nextPage}${sortQuery}`);
  }, [location.pathname, fetcher, nextPage, searchParams]);

  const isLoading = fetcher.state === 'loading';

  useEffect(() => {
    if (!fetcher.data) {
      return;
    }

    setData(
      (prev) =>
        [
          ...prev,
          ...fetcher.data!.paginatedData.data,
        ] as LoaderT['paginatedData']['data'],
    );
    setNextPage(
      fetcher.data!.paginatedData.links.next
        ? fetcher.data!.paginatedData.meta.current_page + 1
        : null,
    );
  }, [fetcher.data]);

  useEffect(() => {
    // Reset data when location or sort order changes
    setData(paginatedData.data as LoaderT['paginatedData']['data']);
    setNextPage(
      paginatedData.links.next ? paginatedData.meta.current_page + 1 : null,
    );
  }, [location.pathname, searchParams, paginatedData]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const onScroll = () => {
      const documentHeight = document.documentElement.scrollHeight;
      const scrollDifference = Math.floor(window.innerHeight + window.scrollY);
      const scrollEnded = documentHeight < scrollDifference + 5;

      if (scrollEnded && fetcher.state !== 'loading' && nextPage) {
        loadMore();
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [loadMore, fetcher.state, nextPage]);

  return {
    loadMore,
    data,
    isLoading: isLoading,
    hasNextPage: !!nextPage,
  };
};

export default useInfiniteFetcher;
